import React from "react";
import MainLayout from "../components/layoutmain";
import { StaticImage } from "gatsby-plugin-image";

export default function Carrieres() {
  const seoProps = {
    title: "Numeko - Carrières",
    description:
      "Rejoindre une société pas comme les autres. Nous recherchons tout d'abord des femmes & hommes passionnés et ingénieux.",
  };
  return (
    <MainLayout seoProps={seoProps}>
      <div className={"carrieres"}>
        <div className={"bloc-category bloc-presentation"}>
          <h1>Rejoignez une société pas comme les autres !</h1>
          <hr />
          <h4>
            Nous recherchons tout d'abord des femmes & hommes{" "}
            <span>passionnés</span> et <span>ingénieux</span>.
          </h4>
        </div>
        <div className={"bloc-category bloc-content"}>
          <div className={"description"}>
            <p>
              Aucune culture d'entreprise imposée car celle-ci est{" "}
              <span>façonnée</span> chaque jour par le <span>partage</span>, l'
              <span>échange</span> et la <span>diversité</span> de nos
              collaborateurs.
            </p>
            <p>
              <span>Ensemble</span>, nous construisons des projets{" "}
              <span>porteurs de sens</span> et diffusons des{" "}
              <span>valeurs positives</span>.
            </p>
            <p>
              Tu veux <span>participer</span> à l'évolution de cette entreprise
              ?<br />
              Alors n’hésite pas à nous contacter avec le lien ci-dessous et
              n'oublie pas de nous laisser ton CV.
            </p>
            <a className={"link-contact"} href="mailto:carrieres@numeko.fr">
              <span className={"numeko-icon-mail"} />
              Rejoins-nous
            </a>
          </div>
          <StaticImage
            className={"background-recruitment"}
            src={"../images/background_recruitment.png"}
            alt={"Alexandre Froger Fondateur et CEO de la société Numeko"}
            placeholder={"none"}
            quality={100}
            width={500}
          />
        </div>
      </div>
    </MainLayout>
  );
}
